/* eslint-env browser */

const FIT_KEY = '--wf-rive-fit';
const ALIGNMENT_KEY = '--wf-rive-alignment';

const queryContainerElement = (elementId) =>
  document.querySelector(`[data-w-id="${elementId}"]`);

const getFrontendModule = () => window.Webflow.require('rive');

export const getPluginConfig = (actionItemConfig, key) =>
  actionItemConfig.value.inputs[key];

export const getPluginDuration = () => null; // Not used by this plugin

export const getPluginOrigin = (refState, actionItem) => {
  if (refState) return refState;
  const result = {};
  const {inputs = {}} = actionItem.config.value;
  for (const input in inputs) if (inputs[input] == null) result[input] = 0;
  return result;
};

export const getPluginDestination = (actionItemConfig) =>
  actionItemConfig.value.inputs ?? {};

export const createPluginInstance = (element, actionItem) => {
  const selectorGuids = actionItem.config?.target?.selectorGuids || [];
  if (selectorGuids.length > 0) return element;

  // In this case, we define pluginInstance as a reference to the container element
  const pluginElementId = actionItem?.config?.target?.pluginElement;
  return pluginElementId ? queryContainerElement(pluginElementId) : null;
};

export const renderPlugin = (
  containerElement,
  {PLUGIN_RIVE: props},
  actionItem
) => {
  const frontendModule = getFrontendModule();
  if (!frontendModule) return;
  const instance = frontendModule.getInstance(containerElement);
  const StateMachineInputType = frontendModule.rive.StateMachineInputType;
  const {name, inputs = {}} = actionItem.config.value || {};

  function renderRive(riveInstance) {
    if (riveInstance.loaded) {
      render();
    } else {
      // Render instance immediately on load and then clean up handler
      const onLoad = () => {
        render();
        riveInstance?.off('load', onLoad);
      };

      riveInstance?.on('load', onLoad);
    }

    function render() {
      const stateMachineInputs = riveInstance.stateMachineInputs(name);
      if (stateMachineInputs == null) return; // exit early since no inputs found

      if (!riveInstance.isPlaying) riveInstance.play(name, false); // set autoplay: false because IX engine is controlling playback.

      if (FIT_KEY in inputs || ALIGNMENT_KEY in inputs) {
        const currentLayout = riveInstance.layout;
        const nextFit = inputs[FIT_KEY] ?? currentLayout.fit;
        const nextAlignment = inputs[ALIGNMENT_KEY] ?? currentLayout.alignment;

        if (
          nextFit !== currentLayout.fit ||
          nextAlignment !== currentLayout.alignment
        ) {
          riveInstance.layout = currentLayout.copyWith({
            fit: nextFit,
            alignment: nextAlignment,
          });
        }
      }

      for (const inputName in inputs) {
        if (inputName === FIT_KEY || inputName === ALIGNMENT_KEY) continue;

        const machineInput = stateMachineInputs.find(
          (m) => m.name === inputName
        );

        if (machineInput == null) continue;

        switch (machineInput.type) {
          case StateMachineInputType.Boolean: {
            if (inputs[inputName] != null) {
              const booleanValue = Boolean(inputs[inputName]);
              machineInput.value = booleanValue;
            }
            break;
          }

          case StateMachineInputType.Number: {
            const numberValue = props[inputName];
            if (numberValue != null) machineInput.value = numberValue;
            break;
          }

          case StateMachineInputType.Trigger: {
            if (inputs[inputName]) machineInput.fire();
            break;
          }
        }
      }
    }
  }

  // Render rive if the instance is available
  if (instance?.rive) renderRive(instance.rive);
  // Otherwise, store latest render as a callback to fire when loaded
  else frontendModule.setLoadHandler(containerElement, renderRive);
};

export const clearPlugin = (_containerElement, _actionItem) => null; // Not used by this plugin

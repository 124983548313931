/* eslint-env browser */

import type {
  ContinuousParameterGroupId,
  ActionItemType,
  ActionId,
  FontVariationItemConfigType,
  FontVariationActionConfigType,
  EventType,
  ActionListType,
  ActionItemsType,
  ContinuousParameterGroupType,
} from '@packages/systems/ix2/types-core';

import defaultTo from 'lodash/defaultTo';
import reduce from 'lodash/reduce';
import findLast from 'lodash/findLast';
import {setIn, mergeIn} from 'timm';
import {
  EventTypeConsts,
  EventAppliesTo,
  EventBasedOn,
  ActionTypeConsts,
  IX2EngineConstants,
} from '@packages/systems/ix2/shared-constants';
import shallowEqual from './shallowEqual';

import {optimizeFloat} from './IX2EasingUtils';

// Importing directly to avoid importing the entire shared-utils package.
// eslint-disable-next-line webflow/package-boundaries
import {normalizeColor} from '../../shared-utils/normalizeColor';
import {
  isPluginType,
  getPluginConfig,
  getPluginOrigin,
  getPluginDestination,
  renderPlugin,
  clearPlugin,
} from './IX2VanillaPlugins';

import {
  IS_BROWSER_ENV,
  FLEX_PREFIXED,
  TRANSFORM_PREFIXED,
  TRANSFORM_STYLE_PREFIXED,
} from './IX2BrowserSupport';
import {
  type IX2RawData,
  type rawDataImportedPayload,
} from '@packages/systems/ix2/engine';
import {BreakpointID} from '@packages/systems/style/types';

type Selector = any;

// FIXME: This could be a shared type.
type ElementApi = {
  getStyle: (arg1: HTMLElement, arg2: string) => string;
  setStyle: (arg1: HTMLElement, arg2: string, arg3: string) => void;
  getProperty: (arg1: HTMLElement, arg2: string) => null | string;
  getValidDocument: (arg1: IX2Target) => any;
  getQuerySelector: (arg1: IX2Target) => null | Selector;
  // Should these `Array`s be `NodeList`s?
  queryDocument: (
    arg1: Selector,
    arg2?: Selector | null | undefined
  ) => Array<HTMLElement>;
  getChildElements: (
    // @ts-expect-error - TS2315 - Type 'NodeList' is not generic.
    arg1: NodeList<HTMLElement> | Array<HTMLElement>
  ) => Array<HTMLElement>;
  getSiblingElements: (
    // @ts-expect-error - TS2315 - Type 'NodeList' is not generic.
    arg1: NodeList<HTMLElement> | Array<HTMLElement>
  ) => Array<HTMLElement>;

  matchSelector: (arg1: Selector) => (arg1: HTMLElement) => boolean;
  elementContains: (arg1: HTMLElement, arg2: HTMLElement) => boolean;
  isSiblingNode: (arg1: HTMLElement, arg2: HTMLElement) => boolean;
  // ... we're missing a lot more here ...;
};

const {
  BACKGROUND,
  TRANSFORM,
  TRANSLATE_3D,
  SCALE_3D,
  ROTATE_X,
  ROTATE_Y,
  ROTATE_Z,
  SKEW,
  PRESERVE_3D,
  FLEX,
  OPACITY,
  FILTER,
  FONT_VARIATION_SETTINGS,
  WIDTH,
  HEIGHT,
  BACKGROUND_COLOR,
  BORDER_COLOR,
  COLOR,
  CHILDREN,
  IMMEDIATE_CHILDREN,
  SIBLINGS,
  PARENT,
  DISPLAY,
  WILL_CHANGE,
  AUTO,
  COMMA_DELIMITER,
  COLON_DELIMITER,
  BAR_DELIMITER,
  RENDER_TRANSFORM,
  RENDER_GENERAL,
  RENDER_STYLE,
  RENDER_PLUGIN,
} = IX2EngineConstants;

const {
  TRANSFORM_MOVE,
  TRANSFORM_SCALE,
  TRANSFORM_ROTATE,
  TRANSFORM_SKEW,
  STYLE_OPACITY,
  STYLE_FILTER,
  STYLE_FONT_VARIATION,
  STYLE_SIZE,
  STYLE_BACKGROUND_COLOR,
  STYLE_BORDER,
  STYLE_TEXT_COLOR,
  GENERAL_DISPLAY,
  OBJECT_VALUE,
} = ActionTypeConsts;

export {shallowEqual};

// @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
const trim = (v) => v.trim();

const colorStyleProps = Object.freeze({
  [STYLE_BACKGROUND_COLOR]: BACKGROUND_COLOR,
  [STYLE_BORDER]: BORDER_COLOR,
  [STYLE_TEXT_COLOR]: COLOR,
});

const willChangeProps = Object.freeze({
  [TRANSFORM_PREFIXED]: TRANSFORM,
  [BACKGROUND_COLOR]: BACKGROUND,
  [OPACITY]: OPACITY,
  [FILTER]: FILTER,
  [WIDTH]: WIDTH,
  [HEIGHT]: HEIGHT,
  [FONT_VARIATION_SETTINGS]: FONT_VARIATION_SETTINGS,
});

const objectCache = new Map();

export function clearObjectCache() {
  objectCache.clear();
}

let instanceCount = 1;
export function getInstanceId() {
  return 'i' + instanceCount++;
}

let elementCount = 1;
export function getElementId(ixElements: any, ref: any) {
  // TODO: optimize element lookup
  for (const key in ixElements) {
    const ixEl = ixElements[key];
    if (ixEl && ixEl.ref === ref) {
      return ixEl.id;
    }
  }
  return 'e' + elementCount++;
}

export function reifyState({
  events,
  actionLists,
  site,
}: Partial<IX2RawData> = {}): rawDataImportedPayload {
  const eventTypeMap = reduce(
    events,
    (result, event) => {
      const {eventTypeId} = event;

      if (!result[eventTypeId]) {
        result[eventTypeId] = {} as {[key: string]: EventType};
      }

      result[eventTypeId][event.id] = event;
      return result;
    },
    {} as rawDataImportedPayload['ixData']['eventTypeMap']
  );

  let mediaQueries = site && site.mediaQueries;
  let mediaQueryKeys = [] as BreakpointID[];
  if (mediaQueries) {
    mediaQueryKeys = mediaQueries.map((mq) => mq.key);
  } else {
    mediaQueries = [];
    console.warn(`IX2 missing mediaQueries in site data`);
  }

  return {
    ixData: {
      events,
      actionLists,
      eventTypeMap,
      mediaQueries,
      mediaQueryKeys,
    },
  };
}

const strictEqual = (a: any, b: any) => a === b;

export function observeStore({
  // @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type.
  store,
  // @ts-expect-error - TS7031 - Binding element 'select' implicitly has an 'any' type.
  select,
  // @ts-expect-error - TS7031 - Binding element 'onChange' implicitly has an 'any' type.
  onChange,
  comparator = strictEqual,
}) {
  const {getState, subscribe} = store;
  const unsubscribe = subscribe(handleChange);
  let currentState = select(getState());
  function handleChange() {
    const nextState = select(getState());
    if (nextState == null) {
      unsubscribe();
      return;
    }
    if (!comparator(nextState, currentState)) {
      currentState = nextState;
      onChange(currentState, store);
    }
  }
  return unsubscribe;
}

// @ts-expect-error - TS7006 - Parameter 'target' implicitly has an 'any' type.
function normalizeTarget(target) {
  const type = typeof target;
  if (type === 'string') {
    return {id: target};
  } else if (target != null && type === 'object') {
    const {id, objectId, selector, selectorGuids, appliesTo, useEventTarget} =
      target;
    return {id, objectId, selector, selectorGuids, appliesTo, useEventTarget};
  }
  return {};
}

type IX2Target = any; // serialized IX2EventTargetData

type AffectedElementsProps = {
  config: {
    target: IX2Target;
    targets?: Array<IX2Target>;
  };
  event?: any; // serialized IX2EventType;
  eventTarget?: HTMLElement | null | undefined;
  elementRoot?: HTMLElement | null | undefined;
  elementApi: ElementApi;
};

export function getAffectedElements({
  config,
  event,
  eventTarget,
  elementRoot,
  elementApi,
}: AffectedElementsProps): Array<HTMLElement | any> {
  if (!elementApi) {
    throw new Error('IX2 missing elementApi');
  }

  const {targets} = config;
  if (Array.isArray(targets) && targets.length > 0) {
    return targets.reduce<Array<any>>(
      (accumulator, target): Array<HTMLElement> =>
        accumulator.concat(
          getAffectedElements({
            config: {target},
            event,
            eventTarget,
            elementRoot,
            elementApi,
          })
        ),
      []
    );
  }

  const {
    getValidDocument,
    getQuerySelector,
    queryDocument,
    getChildElements,
    getSiblingElements,
    matchSelector,
    elementContains,
    isSiblingNode,
  } = elementApi;

  const {target} = config;
  if (!target) {
    return [];
  }

  const {
    id,

    objectId,

    selector,

    selectorGuids,

    appliesTo,

    useEventTarget,
  } = normalizeTarget(target);

  if (objectId) {
    const ref = objectCache.has(objectId)
      ? objectCache.get(objectId)
      : objectCache.set(objectId, {}).get(objectId);
    return [ref];
  }

  if (appliesTo === EventAppliesTo.PAGE) {
    const doc = getValidDocument(id);
    return doc ? [doc] : [];
  }

  const overrides = event?.action?.config?.affectedElements ?? {};
  const override = overrides[id || selector] || {};
  const validOverride = Boolean(override.id || override.selector);

  let limitAffectedElements;
  let baseSelector;
  let finalSelector;

  const eventTargetSelector =
    event && getQuerySelector(normalizeTarget(event.target));

  if (validOverride) {
    limitAffectedElements = override.limitAffectedElements;
    baseSelector = eventTargetSelector;
    finalSelector = getQuerySelector(override);
  } else {
    // pass in selectorGuids as well for server-side rendering.
    baseSelector = finalSelector = getQuerySelector({
      id,
      selector,
      selectorGuids,
    });
  }

  if (event && useEventTarget) {
    // eventTarget is not defined when this function is called in a clear request, so find
    // all target elements associated with the event data, and return affected elements.
    const eventTargets =
      eventTarget && (finalSelector || useEventTarget === true)
        ? [eventTarget]
        : queryDocument(eventTargetSelector);

    if (finalSelector) {
      if (useEventTarget === PARENT) {
        return queryDocument(finalSelector).filter((parentElement) =>
          eventTargets.some((targetElement) =>
            elementContains(parentElement, targetElement)
          )
        );
      }
      if (useEventTarget === CHILDREN) {
        return queryDocument(finalSelector).filter((childElement) =>
          eventTargets.some((targetElement) =>
            elementContains(targetElement, childElement)
          )
        );
      }
      if (useEventTarget === SIBLINGS) {
        return queryDocument(finalSelector).filter((siblingElement) =>
          eventTargets.some((targetElement) =>
            isSiblingNode(targetElement, siblingElement)
          )
        );
      }
    }
    return eventTargets;
  }

  if (baseSelector == null || finalSelector == null) {
    return [];
  }

  if (IS_BROWSER_ENV && elementRoot) {
    return queryDocument(finalSelector).filter((element) =>
      elementRoot.contains(element)
    );
  }

  if (limitAffectedElements === CHILDREN) {
    return queryDocument(baseSelector, finalSelector);
  } else if (limitAffectedElements === IMMEDIATE_CHILDREN) {
    return getChildElements(queryDocument(baseSelector)).filter(
      matchSelector(finalSelector)
    );
  } else if (limitAffectedElements === SIBLINGS) {
    return getSiblingElements(queryDocument(baseSelector)).filter(
      matchSelector(finalSelector)
    );
  } else {
    return queryDocument(finalSelector);
  }
}

// @ts-expect-error - TS7031 - Binding element 'element' implicitly has an 'any' type. | TS7031 - Binding element 'actionItem' implicitly has an 'any' type.
export function getComputedStyle({element, actionItem}) {
  if (!IS_BROWSER_ENV) {
    return {};
  }
  const {actionTypeId} = actionItem;
  switch (actionTypeId) {
    case STYLE_SIZE:
    case STYLE_BACKGROUND_COLOR:
    case STYLE_BORDER:
    case STYLE_TEXT_COLOR:
    case GENERAL_DISPLAY:
      return window.getComputedStyle(element);
    default:
      return {};
  }
}

const pxValueRegex = /px/;

// @ts-expect-error - TS7006 - Parameter 'filters' implicitly has an 'any' type.
const getFilterDefaults = (actionState: any, filters) =>
  // @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type. | TS7006 - Parameter 'filter' implicitly has an 'any' type.
  filters.reduce((result, filter) => {
    if (result[filter.type] == null) {
      result[filter.type] =
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<{ blur: 0; 'hue-rotate': 0; invert: 0; grayscale: 0; saturate: 100; sepia: 0; contrast: 100; brightness: 100; }>'.
        filterDefaults[filter.type];
    }

    return result;
  }, actionState || {});

const getFontVariationDefaults = (
  actionState: any,
  fontVariations: Array<FontVariationItemConfigType>
) =>
  fontVariations.reduce((result, fontVariation) => {
    if (result[fontVariation.type] == null) {
      result[fontVariation.type] =
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Readonly<{ wght: 0; opsz: 0; wdth: 0; slnt: 0; }>'.
        fontVariationDefaults[fontVariation.type] ||
        // @ts-expect-error - TS2339 - Property 'defaultValue' does not exist on type 'FontVariationItemConfigType'.
        fontVariation.defaultValue ||
        0;
    }

    return result;
  }, actionState || {});

export function getInstanceOrigin(
  element: HTMLElement,

  refState = {},
  computedStyle:
    | {
        [key: string]: string;
      }
    | null
    | undefined = {},
  actionItem: ActionItemType,
  elementApi: ElementApi
) {
  const {getStyle} = elementApi;
  // Flow Hack: Passing actionTypeId to isPluginType and then trying
  // to do type refinement using the same variable via a switch statement
  // breaks down. This is is a workaround to ensure we can use type refinement.
  const {actionTypeId} = actionItem;

  if (isPluginType(actionTypeId)) {
    // @ts-expect-error - TS2345 - Argument of type '"TRANSFORM_MOVE" | "TRANSFORM_SCALE" | "TRANSFORM_ROTATE" | "TRANSFORM_SKEW" | "STYLE_OPACITY" | "STYLE_SIZE" | "STYLE_FILTER" | "STYLE_FONT_VARIATION" | "STYLE_BACKGROUND_COLOR" | "STYLE_BORDER" | "STYLE_TEXT_COLOR" | "PLUGIN_LOTTIE" | "GENERAL_DISPLAY"' is not assignable to parameter of type 'PluginType'. | TS7053 - Element implicitly has an 'any' type because expression of type '"TRANSFORM_MOVE" | "TRANSFORM_SCALE" | "TRANSFORM_ROTATE" | "TRANSFORM_SKEW" | "STYLE_OPACITY" | "STYLE_SIZE" | "STYLE_FILTER" | "STYLE_FONT_VARIATION" | "STYLE_BACKGROUND_COLOR" | "STYLE_BORDER" | "STYLE_TEXT_COLOR" | "PLUGIN_LOTTIE" | "GENERAL_DISPLAY"' can't be used to index type '{}'.
    return getPluginOrigin(actionTypeId)(refState[actionTypeId], actionItem);
  }

  switch (actionItem.actionTypeId) {
    case TRANSFORM_MOVE:
    case TRANSFORM_SCALE:
    case TRANSFORM_ROTATE:
    case TRANSFORM_SKEW: {
      return (
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"TRANSFORM_MOVE" | "TRANSFORM_SCALE" | "TRANSFORM_ROTATE" | "TRANSFORM_SKEW"' can't be used to index type '{}'.
        refState[actionItem.actionTypeId] ||
        transformDefaults[actionItem.actionTypeId]
      );
    }
    case STYLE_FILTER:
      return getFilterDefaults(
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"STYLE_FILTER"' can't be used to index type '{}'.
        refState[actionItem.actionTypeId],
        actionItem.config.filters
      );
    case STYLE_FONT_VARIATION:
      return getFontVariationDefaults(
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"STYLE_FONT_VARIATION"' can't be used to index type '{}'.
        refState[actionItem.actionTypeId],
        actionItem.config.fontVariations
      );
    case STYLE_OPACITY:
      return {value: defaultTo(parseFloat(getStyle(element, OPACITY)), 1.0)};
    case STYLE_SIZE: {
      const inlineWidth = getStyle(element, WIDTH);
      const inlineHeight = getStyle(element, HEIGHT);
      let widthValue;
      let heightValue;
      // When destination unit is 'AUTO', ensure origin values are in px
      if (actionItem.config.widthUnit === AUTO) {
        widthValue = pxValueRegex.test(inlineWidth)
          ? parseFloat(inlineWidth)
          : // @ts-expect-error - TS18047 - 'computedStyle' is possibly 'null'.
            parseFloat(computedStyle.width);
      } else {
        widthValue = defaultTo(
          parseFloat(inlineWidth),
          // @ts-expect-error - TS18047 - 'computedStyle' is possibly 'null'.
          parseFloat(computedStyle.width)
        );
      }
      if (actionItem.config.heightUnit === AUTO) {
        heightValue = pxValueRegex.test(inlineHeight)
          ? parseFloat(inlineHeight)
          : // @ts-expect-error - TS18047 - 'computedStyle' is possibly 'null'.
            parseFloat(computedStyle.height);
      } else {
        heightValue = defaultTo(
          parseFloat(inlineHeight),
          // @ts-expect-error - TS18047 - 'computedStyle' is possibly 'null'.
          parseFloat(computedStyle.height)
        );
      }
      return {
        widthValue,
        heightValue,
      };
    }
    case STYLE_BACKGROUND_COLOR:
    case STYLE_BORDER:
    case STYLE_TEXT_COLOR:
      return parseColor({
        element,
        actionTypeId: actionItem.actionTypeId,
        computedStyle,
        getStyle,
      });
    case GENERAL_DISPLAY:
      return {
        // @ts-expect-error - TS18047 - 'computedStyle' is possibly 'null'.
        value: defaultTo(getStyle(element, DISPLAY), computedStyle.display),
      };
    // @ts-expect-error - `OBJECT_VALUE` is not an expected `actionTypeId`
    case OBJECT_VALUE:
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'. | TS2339 - Property 'actionTypeId' does not exist on type 'never'.
      return refState[actionItem.actionTypeId] || {value: 0};
    default: {
      // As far as the type system can tell, we're missing a handler for
      // PLUGIN_LOTTIE.
      //
      // This is actually handled by `isPluginType` above.
      //
      /*:: (actionItem: empty); */
      return;
    }
  }
}

// @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type. | TS7006 - Parameter 'filter' implicitly has an 'any' type.
const reduceFilters = (result, filter) => {
  if (filter) {
    result[filter.type] = filter.value || 0;
  }
  return result;
};

const reduceFontVariations = (
  result: Record<any, any>,
  fontVariation:
    | FontVariationItemConfigType
    | {
        id: null | string;
        type: string;
        value: number;
      }
) => {
  if (fontVariation) {
    result[fontVariation.type] = fontVariation.value || 0;
  }
  return result;
};

export const getItemConfigByKey = (
  actionTypeId: any,
  key: any,
  config: any
) => {
  if (isPluginType(actionTypeId)) {
    return getPluginConfig(actionTypeId)(config, key);
  }

  switch (actionTypeId) {
    case STYLE_FILTER: {
      const filter = findLast(config.filters, ({type}) => type === key);
      return filter ? filter.value : 0;
    }
    case STYLE_FONT_VARIATION: {
      const fontVariation = findLast(
        config.fontVariations,
        ({type}) => type === key
      );
      return fontVariation ? fontVariation.value : 0;
    }
    default:
      return config[key];
  }
};

export function getDestinationValues({
  element,
  actionItem,
  elementApi,
}: {
  element: HTMLElement;
  actionItem: ActionItemType;
  elementApi: ElementApi;
}) {
  if (isPluginType(actionItem.actionTypeId)) {
    // @ts-expect-error - TS2345 - Argument of type '"TRANSFORM_MOVE" | "TRANSFORM_SCALE" | "TRANSFORM_ROTATE" | "TRANSFORM_SKEW" | "STYLE_OPACITY" | "STYLE_SIZE" | "STYLE_FILTER" | "STYLE_FONT_VARIATION" | "STYLE_BACKGROUND_COLOR" | "STYLE_BORDER" | "STYLE_TEXT_COLOR" | "PLUGIN_LOTTIE" | "GENERAL_DISPLAY"' is not assignable to parameter of type 'PluginType'.
    return getPluginDestination(actionItem.actionTypeId)(actionItem.config);
  }

  switch (actionItem.actionTypeId) {
    case TRANSFORM_MOVE:
    case TRANSFORM_SCALE:
    case TRANSFORM_ROTATE:
    case TRANSFORM_SKEW: {
      const {xValue, yValue, zValue} = actionItem.config;
      return {xValue, yValue, zValue};
    }
    case STYLE_SIZE: {
      const {getStyle, setStyle, getProperty} = elementApi;
      const {widthUnit, heightUnit} = actionItem.config;
      let {widthValue, heightValue} = actionItem.config;
      if (!IS_BROWSER_ENV) {
        return {widthValue, heightValue};
      }
      if (widthUnit === AUTO) {
        const temp = getStyle(element, WIDTH);
        setStyle(element, WIDTH, '');
        // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'number | undefined'.
        widthValue = getProperty(element, 'offsetWidth');
        setStyle(element, WIDTH, temp);
      }
      if (heightUnit === AUTO) {
        const temp = getStyle(element, HEIGHT);
        setStyle(element, HEIGHT, '');
        // @ts-expect-error - TS2322 - Type 'string | null' is not assignable to type 'number | undefined'.
        heightValue = getProperty(element, 'offsetHeight');
        setStyle(element, HEIGHT, temp);
      }
      return {widthValue, heightValue};
    }
    case STYLE_BACKGROUND_COLOR:
    case STYLE_BORDER:
    case STYLE_TEXT_COLOR: {
      const {rValue, gValue, bValue, aValue, globalSwatchId} =
        actionItem.config;

      if (globalSwatchId && globalSwatchId.startsWith('--')) {
        const {getStyle} = elementApi;
        const value = getStyle(element, globalSwatchId);
        const normalizedValue = normalizeColor(value);
        return {
          rValue: normalizedValue.red,
          gValue: normalizedValue.green,
          bValue: normalizedValue.blue,
          aValue: normalizedValue.alpha,
        };
      }

      return {rValue, gValue, bValue, aValue};
    }
    case STYLE_FILTER: {
      return actionItem.config.filters.reduce<Record<string, any>>(
        reduceFilters,
        {}
      );
    }
    case STYLE_FONT_VARIATION: {
      return actionItem.config.fontVariations.reduce<Record<string, any>>(
        reduceFontVariations,
        {}
      );
    }
    default: {
      const {value} = actionItem.config;
      return {value};
    }
  }
}

export function getRenderType(actionTypeId: any) {
  if (/^TRANSFORM_/.test(actionTypeId)) {
    return RENDER_TRANSFORM;
  }
  if (/^STYLE_/.test(actionTypeId)) {
    return RENDER_STYLE;
  }
  if (/^GENERAL_/.test(actionTypeId)) {
    return RENDER_GENERAL;
  }
  if (/^PLUGIN_/.test(actionTypeId)) {
    return RENDER_PLUGIN;
  }
}

export function getStyleProp(renderType: any, actionTypeId: any) {
  return renderType === RENDER_STYLE
    ? actionTypeId.replace('STYLE_', '').toLowerCase()
    : null;
}

export function renderHTMLElement(
  element: any,

  refState: any,

  actionState: any,

  eventId: any,

  actionItem: any,

  styleProp: any,

  elementApi: any,

  renderType: any,

  pluginInstance: any
) {
  switch (renderType) {
    case RENDER_TRANSFORM: {
      return renderTransform(
        element,
        refState,
        actionState,
        actionItem,
        elementApi
      );
    }
    case RENDER_STYLE: {
      return renderStyle(
        element,
        refState,
        actionState,
        actionItem,
        styleProp,
        elementApi
      );
    }
    case RENDER_GENERAL: {
      return renderGeneral(element, actionItem, elementApi);
    }
    case RENDER_PLUGIN: {
      const {actionTypeId} = actionItem;
      if (isPluginType(actionTypeId)) {
        return renderPlugin(actionTypeId)(pluginInstance, refState, actionItem);
      }
    }
  }
}

const transformDefaults = {
  [TRANSFORM_MOVE]: Object.freeze({
    xValue: 0,
    yValue: 0,
    zValue: 0,
  }),
  [TRANSFORM_SCALE]: Object.freeze({
    xValue: 1,
    yValue: 1,
    zValue: 1,
  }),
  [TRANSFORM_ROTATE]: Object.freeze({
    xValue: 0,
    yValue: 0,
    zValue: 0,
  }),
  [TRANSFORM_SKEW]: Object.freeze({
    xValue: 0,
    yValue: 0,
  }),
} as const;

const filterDefaults = Object.freeze({
  blur: 0,
  'hue-rotate': 0,
  invert: 0,
  grayscale: 0,
  saturate: 100,
  sepia: 0,
  contrast: 100,
  brightness: 100,
});

const fontVariationDefaults = Object.freeze({
  wght: 0,
  opsz: 0,
  wdth: 0,
  slnt: 0,
});

// @ts-expect-error - TS7006 - Parameter 'filterType' implicitly has an 'any' type. | TS7006 - Parameter 'actionItemConfig' implicitly has an 'any' type.
const getFilterUnit = (filterType, actionItemConfig) => {
  const filter = findLast(
    actionItemConfig.filters,
    ({type}) => type === filterType
  );

  if (filter && filter.unit) {
    return filter.unit;
  }

  switch (filterType) {
    case 'blur':
      return 'px';
    case 'hue-rotate':
      return 'deg';
    default:
      return '%';
  }
};

const transformKeys = Object.keys(transformDefaults);

function renderTransform(
  element: any,
  refState: any,
  actionState: any,
  actionItem: any,
  elementApi: any
) {
  const newTransform = transformKeys
    .map((actionTypeId) => {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly TRANSFORM_MOVE: Readonly<{ xValue: 0; yValue: 0; zValue: 0; }>; readonly TRANSFORM_SCALE: Readonly<{ xValue: 1; yValue: 1; zValue: 1; }>; readonly TRANSFORM_ROTATE: Readonly<{ xValue: 0; yValue: 0; zValue: 0; }>; readonly TRANSFORM_SKEW: Readonly<...>; }'.
      const defaults = transformDefaults[actionTypeId];
      const {
        xValue = defaults.xValue,
        yValue = defaults.yValue,

        zValue = defaults.zValue,
        xUnit = '',
        yUnit = '',
        zUnit = '',
      } = refState[actionTypeId] || {};
      switch (actionTypeId) {
        case TRANSFORM_MOVE:
          return `${TRANSLATE_3D}(${xValue}${xUnit}, ${yValue}${yUnit}, ${zValue}${zUnit})`;
        case TRANSFORM_SCALE:
          return `${SCALE_3D}(${xValue}${xUnit}, ${yValue}${yUnit}, ${zValue}${zUnit})`;
        case TRANSFORM_ROTATE:
          return `${ROTATE_X}(${xValue}${xUnit}) ${ROTATE_Y}(${yValue}${yUnit}) ${ROTATE_Z}(${zValue}${zUnit})`;
        case TRANSFORM_SKEW:
          return `${SKEW}(${xValue}${xUnit}, ${yValue}${yUnit})`;
        default:
          return '';
      }
    })
    .join(' ');

  const {setStyle} = elementApi;
  addWillChange(element, TRANSFORM_PREFIXED, elementApi);
  setStyle(element, TRANSFORM_PREFIXED, newTransform);

  // Set transform-style: preserve-3d
  if (hasDefined3dTransform(actionItem, actionState)) {
    setStyle(element, TRANSFORM_STYLE_PREFIXED, PRESERVE_3D);
  }
}

function renderFilter(
  element: HTMLElement,
  actionState: any,
  // @ts-expect-error - TS7006 - Parameter 'actionItemConfig' implicitly has an 'any' type.
  actionItemConfig,
  elementApi: any
) {
  const filterValue = reduce(
    actionState,
    (result, value, type) =>
      `${result} ${type}(${value}${getFilterUnit(type, actionItemConfig)})`,
    ''
  );

  const {setStyle} = elementApi;
  addWillChange(element, FILTER, elementApi);
  setStyle(element, FILTER, filterValue);
}

function renderFontVariation(
  element: HTMLElement,
  actionState: any,
  actionItemConfig: FontVariationActionConfigType,
  elementApi: ElementApi
) {
  const fontVariationValue = reduce(
    actionState,
    (result, value, type) => {
      // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
      result.push(`"${type}" ${value}`);
      return result;
    },
    []
  ).join(', ');

  const {setStyle} = elementApi;
  addWillChange(element, FONT_VARIATION_SETTINGS, elementApi);
  setStyle(element, FONT_VARIATION_SETTINGS, fontVariationValue);
}

// @ts-expect-error - TS7031 - Binding element 'actionTypeId' implicitly has an 'any' type. | TS7031 - Binding element 'xValue' implicitly has an 'any' type. | TS7031 - Binding element 'yValue' implicitly has an 'any' type. | TS7031 - Binding element 'zValue' implicitly has an 'any' type.
function hasDefined3dTransform({actionTypeId}, {xValue, yValue, zValue}) {
  // TRANSLATE_Z
  return (
    (actionTypeId === TRANSFORM_MOVE && zValue !== undefined) ||
    // SCALE_Z
    (actionTypeId === TRANSFORM_SCALE && zValue !== undefined) ||
    // ROTATE_X or ROTATE_Y
    (actionTypeId === TRANSFORM_ROTATE &&
      (xValue !== undefined || yValue !== undefined))
  );
}

const paramCapture = '\\(([^)]+)\\)';
const rgbValidRegex = /^rgb/;
const rgbMatchRegex = RegExp(`rgba?${paramCapture}`);

function getFirstMatch(regex: RegExp, value: string) {
  const match = regex.exec(value);
  return match ? match[1] : '';
}

// @ts-expect-error - TS7031 - Binding element 'element' implicitly has an 'any' type. | TS7031 - Binding element 'actionTypeId' implicitly has an 'any' type. | TS7031 - Binding element 'computedStyle' implicitly has an 'any' type. | TS7031 - Binding element 'getStyle' implicitly has an 'any' type.
function parseColor({element, actionTypeId, computedStyle, getStyle}): {
  rValue: number;
  gValue: number;
  bValue: number;
  aValue: number;
} {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Readonly<{ STYLE_BACKGROUND_COLOR: "backgroundColor"; STYLE_BORDER: "borderColor"; STYLE_TEXT_COLOR: "color"; }>'.
  const prop = colorStyleProps[actionTypeId];
  const inlineValue = getStyle(element, prop);
  const value = rgbValidRegex.test(inlineValue)
    ? inlineValue
    : computedStyle[prop];
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const matches = getFirstMatch(rgbMatchRegex, value).split(COMMA_DELIMITER);
  return {
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    rValue: defaultTo(parseInt(matches[0], 10), 255),
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    gValue: defaultTo(parseInt(matches[1], 10), 255),
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    bValue: defaultTo(parseInt(matches[2], 10), 255),
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    aValue: defaultTo(parseFloat(matches[3]), 1),
  };
}

function renderStyle(
  element: HTMLElement,
  refState: any,
  actionState: any,
  actionItem: ActionItemType,
  styleProp: any,
  elementApi: any
) {
  const {setStyle} = elementApi;
  switch (actionItem.actionTypeId) {
    case STYLE_SIZE: {
      let {widthUnit = '', heightUnit = ''} = actionItem.config;
      const {widthValue, heightValue} = actionState;
      if (widthValue !== undefined) {
        if (widthUnit === AUTO) {
          widthUnit = 'px';
        }
        addWillChange(element, WIDTH, elementApi);
        setStyle(element, WIDTH, widthValue + widthUnit);
      }
      if (heightValue !== undefined) {
        if (heightUnit === AUTO) {
          heightUnit = 'px';
        }
        addWillChange(element, HEIGHT, elementApi);
        setStyle(element, HEIGHT, heightValue + heightUnit);
      }
      break;
    }
    case STYLE_FILTER: {
      renderFilter(element, actionState, actionItem.config, elementApi);
      break;
    }
    case STYLE_FONT_VARIATION: {
      renderFontVariation(element, actionState, actionItem.config, elementApi);
      break;
    }
    case STYLE_BACKGROUND_COLOR:
    case STYLE_BORDER:
    case STYLE_TEXT_COLOR: {
      const prop = colorStyleProps[actionItem.actionTypeId];

      const rValue = Math.round(actionState.rValue);
      const gValue = Math.round(actionState.gValue);
      const bValue = Math.round(actionState.bValue);
      const aValue = actionState.aValue;

      addWillChange(element, prop, elementApi);

      setStyle(
        element,
        prop,
        aValue >= 1
          ? `rgb(${rValue},${gValue},${bValue})`
          : `rgba(${rValue},${gValue},${bValue},${aValue})`
      );
      break;
    }
    default: {
      // @ts-expect-error - TS2339 - Property 'unit' does not exist on type '{ delay: number; easing: IX2EasingType; duration: number; target: ActionItemTargetType; xValue: number | undefined; yValue: number | undefined; zValue: number | undefined; xUnit: "%" | ... 4 more ... | "VW"; yUnit: "%" | ... 4 more ... | "VW"; zUnit: "%" | ... 4 more ... | "VW"; } | ... 5 more ... | { ...; }'.
      const {unit = ''} = actionItem.config;
      addWillChange(element, styleProp, elementApi);
      setStyle(element, styleProp, actionState.value + unit);
      break;
    }
  }
}

function renderGeneral(element: any, actionItem: any, elementApi: any) {
  const {setStyle} = elementApi;
  switch (actionItem.actionTypeId) {
    case GENERAL_DISPLAY: {
      const {value} = actionItem.config;
      if (value === FLEX && IS_BROWSER_ENV) {
        setStyle(element, DISPLAY, FLEX_PREFIXED);
      } else {
        setStyle(element, DISPLAY, value);
      }
      return;
    }
  }
}

// @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type. | TS7006 - Parameter 'prop' implicitly has an 'any' type.
function addWillChange(element, prop, elementApi: ElementApi) {
  if (!IS_BROWSER_ENV) {
    return;
  }
  const validProp = willChangeProps[prop];
  if (!validProp) {
    return;
  }
  const {getStyle, setStyle} = elementApi;
  const value = getStyle(element, WILL_CHANGE);
  if (!value) {
    setStyle(element, WILL_CHANGE, validProp);
    return;
  }
  const values = value.split(COMMA_DELIMITER).map(trim);
  if (values.indexOf(validProp) === -1) {
    setStyle(
      element,
      WILL_CHANGE,
      values.concat(validProp).join(COMMA_DELIMITER)
    );
  }
}

// @ts-expect-error - TS7006 - Parameter 'prop' implicitly has an 'any' type.
function removeWillChange(element: HTMLElement, prop, elementApi: any) {
  if (!IS_BROWSER_ENV) {
    return;
  }
  const validProp = willChangeProps[prop];
  if (!validProp) {
    return;
  }
  const {getStyle, setStyle} = elementApi;
  const value = getStyle(element, WILL_CHANGE);
  if (!value || value.indexOf(validProp) === -1) {
    return;
  }
  setStyle(
    element,
    WILL_CHANGE,
    value
      .split(COMMA_DELIMITER)
      .map(trim)
      // @ts-expect-error - TS7006 - Parameter 'v' implicitly has an 'any' type.
      .filter((v) => v !== validProp)
      .join(COMMA_DELIMITER)
  );
}

// @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type. | TS7031 - Binding element 'elementApi' implicitly has an 'any' type.
export function clearAllStyles({store, elementApi}) {
  const {ixData} = store.getState();
  const {events = {}, actionLists = {}} = ixData;
  Object.keys(events).forEach((eventId) => {
    const event = events[eventId];
    const {config} = event.action;
    const {actionListId} = config;
    const actionList = actionLists[actionListId];
    if (actionList) {
      clearActionListStyles({actionList, event, elementApi});
    }
  });
  Object.keys(actionLists).forEach((actionListId) => {
    // @ts-expect-error - TS2345 - Argument of type '{ actionList: any; elementApi: any; }' is not assignable to parameter of type '{ actionList?: {} | undefined; event: any; elementApi: any; }'.
    clearActionListStyles({actionList: actionLists[actionListId], elementApi});
  });
}

// @ts-expect-error - TS7031 - Binding element 'event' implicitly has an 'any' type. | TS7031 - Binding element 'elementApi' implicitly has an 'any' type.
function clearActionListStyles({actionList = {}, event, elementApi}) {
  // @ts-expect-error - TS2339 - Property 'actionItemGroups' does not exist on type '{}'. | TS2339 - Property 'continuousParameterGroups' does not exist on type '{}'.
  const {actionItemGroups, continuousParameterGroups} = actionList;
  actionItemGroups &&
    // @ts-expect-error - TS7006 - Parameter 'actionGroup' implicitly has an 'any' type.
    actionItemGroups.forEach((actionGroup) => {
      clearActionGroupStyles({actionGroup, event, elementApi});
    });
  continuousParameterGroups &&
    // @ts-expect-error - TS7006 - Parameter 'paramGroup' implicitly has an 'any' type.
    continuousParameterGroups.forEach((paramGroup) => {
      const {continuousActionGroups} = paramGroup;
      // @ts-expect-error - TS7006 - Parameter 'actionGroup' implicitly has an 'any' type.
      continuousActionGroups.forEach((actionGroup) => {
        clearActionGroupStyles({actionGroup, event, elementApi});
      });
    });
}

// @ts-expect-error - TS7031 - Binding element 'actionGroup' implicitly has an 'any' type. | TS7031 - Binding element 'event' implicitly has an 'any' type. | TS7031 - Binding element 'elementApi' implicitly has an 'any' type.
function clearActionGroupStyles({actionGroup, event, elementApi}) {
  const {actionItems} = actionGroup;
  // @ts-expect-error - TS7006 - Parameter 'actionItem' implicitly has an 'any' type.
  actionItems.forEach((actionItem) => {
    const {actionTypeId, config} = actionItem;
    let clearElement;

    if (isPluginType(actionTypeId)) {
      // @ts-expect-error - TS7006 - Parameter 'ref' implicitly has an 'any' type.
      clearElement = (ref) => clearPlugin(actionTypeId)(ref, actionItem);
    } else {
      clearElement = processElementByType({
        effect: clearStyleProp,
        actionTypeId,
        elementApi,
      });
    }

    getAffectedElements({config, event, elementApi}).forEach(clearElement);
  });
}

export function cleanupHTMLElement(
  element: any,
  actionItem: any,
  elementApi: any
) {
  const {setStyle, getStyle} = elementApi;
  const {actionTypeId} = actionItem;

  if (actionTypeId === STYLE_SIZE) {
    const {config} = actionItem;
    if (config.widthUnit === AUTO) {
      setStyle(element, WIDTH, '');
    }
    if (config.heightUnit === AUTO) {
      setStyle(element, HEIGHT, '');
    }
  }

  if (getStyle(element, WILL_CHANGE)) {
    processElementByType({effect: removeWillChange, actionTypeId, elementApi})(
      element
    );
  }
}

const processElementByType =
  ({
    effect,
    actionTypeId,
    elementApi,
  }: {
    effect: (
      element: HTMLElement,
      prop: any | undefined | string,

      elementApi?: any
    ) => void;
    actionTypeId: ActionId;
    elementApi: ElementApi;
  }) =>
  // @ts-expect-error - TS7006 - Parameter 'element' implicitly has an 'any' type.
  (element) => {
    switch (actionTypeId) {
      case TRANSFORM_MOVE:
      case TRANSFORM_SCALE:
      case TRANSFORM_ROTATE:
      case TRANSFORM_SKEW:
        effect(element, TRANSFORM_PREFIXED, elementApi);
        break;
      case STYLE_FILTER:
        effect(element, FILTER, elementApi);
        break;
      case STYLE_FONT_VARIATION:
        effect(element, FONT_VARIATION_SETTINGS, elementApi);
        break;
      case STYLE_OPACITY:
        effect(element, OPACITY, elementApi);
        break;
      case STYLE_SIZE:
        effect(element, WIDTH, elementApi);
        effect(element, HEIGHT, elementApi);
        break;
      case STYLE_BACKGROUND_COLOR:
      case STYLE_BORDER:
      case STYLE_TEXT_COLOR:
        effect(element, colorStyleProps[actionTypeId], elementApi);
        break;
      case GENERAL_DISPLAY:
        effect(element, DISPLAY, elementApi);
        break;
    }
  };

// @ts-expect-error - TS7006 - Parameter 'prop' implicitly has an 'any' type.
function clearStyleProp(element: HTMLElement, prop, elementApi: any) {
  const {setStyle} = elementApi;
  removeWillChange(element, prop, elementApi);
  setStyle(element, prop, '');
  // Clear transform-style: preserve-3d
  if (prop === TRANSFORM_PREFIXED) {
    setStyle(element, TRANSFORM_STYLE_PREFIXED, '');
  }
}

export function getMaxDurationItemIndex(actionItems: any) {
  let maxDuration = 0;
  let resultIndex = 0;
  // @ts-expect-error - TS7006 - Parameter 'actionItem' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
  actionItems.forEach((actionItem, index) => {
    const {config} = actionItem;
    const total = config.delay + config.duration;
    if (total >= maxDuration) {
      maxDuration = total;
      resultIndex = index;
    }
  });
  return resultIndex;
}

export function getActionListProgress(actionList: any, instance: any) {
  const {actionItemGroups, useFirstGroupAsInitialState} = actionList;
  const {actionItem: instanceItem, verboseTimeElapsed = 0} = instance;
  let totalDuration = 0;
  let elapsedDuration = 0;
  // @ts-expect-error - TS7006 - Parameter 'group' implicitly has an 'any' type. | TS7006 - Parameter 'index' implicitly has an 'any' type.
  actionItemGroups.forEach((group, index) => {
    if (useFirstGroupAsInitialState && index === 0) {
      return;
    }
    const {actionItems} = group;
    const carrierItem = actionItems[getMaxDurationItemIndex(actionItems)];
    const {config, actionTypeId} = carrierItem;
    if (instanceItem.id === carrierItem.id) {
      elapsedDuration = totalDuration + verboseTimeElapsed;
    }
    const duration =
      getRenderType(actionTypeId) === RENDER_GENERAL ? 0 : config.duration;
    totalDuration += config.delay + duration;
  });
  return totalDuration > 0 ? optimizeFloat(elapsedDuration / totalDuration) : 0;
}

export function reduceListToGroup({
  actionList,
  actionItemId,
  rawData,
}: {
  actionList: ActionListType;
  actionItemId: string;
  rawData: IX2RawData;
}) {
  // @ts-expect-error - FIXME - TS2339 - Property 'actionItemGroups' does not exist on type 'ActionListType'.
  const {actionItemGroups, continuousParameterGroups} = actionList;
  const newActionItems: Array<ActionItemType> = [];

  const takeItemUntilMatch = (actionItem: ActionItemType) => {
    newActionItems.push(
      mergeIn(actionItem, ['config'], {
        delay: 0,
        duration: 0,
      })
    );
    return actionItem.id === actionItemId;
  };

  actionItemGroups &&
    actionItemGroups.some(({actionItems}: {actionItems: ActionItemsType}) => {
      return actionItems.some(takeItemUntilMatch);
    });

  continuousParameterGroups &&
    continuousParameterGroups.some(
      (
        paramGroup:
          | ContinuousParameterGroupType<'MOUSE_X'>
          | ContinuousParameterGroupType<'MOUSE_Y'>
          | ContinuousParameterGroupType<'SCROLL_PROGRESS'>
      ) => {
        const {continuousActionGroups} = paramGroup;
        return continuousActionGroups.some(
          ({actionItems}: {actionItems: ActionItemsType}) => {
            return actionItems.some(takeItemUntilMatch);
          }
        );
      }
    );

  return setIn(rawData, ['actionLists'], {
    [actionList.id]: {
      id: actionList.id,
      actionItemGroups: [
        {
          actionItems: newActionItems,
        },
      ],
    },
  });
}

// @ts-expect-error - TS7031 - Binding element 'basedOn' implicitly has an 'any' type.
export function shouldNamespaceEventParameter(eventTypeId: any, {basedOn}) {
  return (
    (eventTypeId === EventTypeConsts.SCROLLING_IN_VIEW &&
      (basedOn === EventBasedOn.ELEMENT || basedOn == null)) ||
    (eventTypeId === EventTypeConsts.MOUSE_MOVE &&
      basedOn === EventBasedOn.ELEMENT)
  );
}

export function getNamespacedParameterId(
  eventStateKey: any,
  continuousParameterGroupId: ContinuousParameterGroupId
) {
  const namespacedParameterId =
    eventStateKey + COLON_DELIMITER + continuousParameterGroupId;

  return namespacedParameterId as ContinuousParameterGroupId;
}

export function shouldAllowMediaQuery(mediaQueries: any, mediaQueryKey: any) {
  // During design mode, current media query key does not exist
  if (mediaQueryKey == null) {
    return true;
  }
  return mediaQueries.indexOf(mediaQueryKey) !== -1;
}

export function mediaQueriesEqual(listA: any, listB: any) {
  return shallowEqual(listA && listA.sort(), listB && listB.sort());
}

export function stringifyTarget(target: any) {
  if (typeof target === 'string') {
    return target;
  }
  if (target.pluginElement && target.objectId) {
    return target.pluginElement + BAR_DELIMITER + target.objectId;
  }
  if (target.objectId) {
    return target.objectId;
  }
  const {id = '', selector = '', useEventTarget = ''} = target;
  return id + BAR_DELIMITER + selector + BAR_DELIMITER + useEventTarget;
}

/* globals window, document */

'use strict';
var IXEvents = require('./webflow-ix-events');

function dispatchCustomEvent(element, eventName) {
  var event = document.createEvent('CustomEvent');
  event.initCustomEvent(eventName, true, true, null);
  element.dispatchEvent(event);
}

/**
 * Webflow: IX Event triggers for other modules
 */

var $ = window.jQuery;
var api = {};
var namespace = '.w-ix';

var eventTriggers = {
  reset: function (i, el) {
    IXEvents.triggers.reset(i, el);
  },
  intro: function (i, el) {
    IXEvents.triggers.intro(i, el);
    dispatchCustomEvent(el, 'COMPONENT_ACTIVE');
  },
  outro: function (i, el) {
    IXEvents.triggers.outro(i, el);
    dispatchCustomEvent(el, 'COMPONENT_INACTIVE');
  },
};

api.triggers = {};

api.types = {
  INTRO: 'w-ix-intro' + namespace,
  OUTRO: 'w-ix-outro' + namespace,
};

$.extend(api.triggers, eventTriggers);

module.exports = api;

/* globals window, document */

/**
 * Webflow: Smooth scroll
 */

var Webflow = require('./webflow-lib');

Webflow.define(
  'scroll',
  (module.exports = function ($) {
    /**
     * A collection of namespaced events found in this module.
     * Namespaced events encapsulate our code, and make it safer and easier
     * for designers to apply custom code overrides.
     * @see https://api.jquery.com/on/#event-names
     * @typedef {Object.<string>} NamespacedEventsCollection
     */
    var NS_EVENTS = {
      WF_CLICK_EMPTY: 'click.wf-empty-link',
      WF_CLICK_SCROLL: 'click.wf-scroll',
    };

    var loc = window.location;
    var history = inIframe() ? null : window.history;
    var $win = $(window);
    var $doc = $(document);
    var $body = $(document.body);

    var animate =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      function (fn) {
        window.setTimeout(fn, 15);
      };
    var rootTag = Webflow.env('editor') ? '.w-editor-body' : 'body';
    var headerSelector =
      'header, ' +
      rootTag +
      ' > .header, ' +
      rootTag +
      ' > .w-nav:not([data-no-scroll])';

    var emptyHrefSelector = 'a[href="#"]';

    /**
     * Select only links whose href:
     * - contains a #
     * - is not one of our namespaced TabLink elements
     * - is not _only_ a #
     */
    var localHrefSelector =
      'a[href*="#"]:not(.w-tab-link):not(' + emptyHrefSelector + ')';

    var scrollTargetOutlineCSS =
      '.wf-force-outline-none[tabindex="-1"]:focus{outline:none;}';

    var focusStylesEl = document.createElement('style');
    focusStylesEl.appendChild(document.createTextNode(scrollTargetOutlineCSS));

    function inIframe() {
      try {
        return Boolean(window.frameElement);
      } catch (e) {
        return true;
      }
    }

    var validHash = /^#[a-zA-Z0-9][\w:.-]*$/;

    /**
     * Determine if link navigates to current page
     * @param {HTMLAnchorElement} link
     */
    function linksToCurrentPage(link) {
      return (
        validHash.test(link.hash) &&
        link.host + link.pathname === loc.host + loc.pathname
      );
    }

    /**
     * Check if the designer has indicated that this page should
     * have no scroll animation, or if the end user has set
     * prefers-reduced-motion in their OS
     */
    const reducedMotionMediaQuery =
      typeof window.matchMedia === 'function' &&
      window.matchMedia('(prefers-reduced-motion: reduce)');
    function reducedMotionEnabled() {
      return (
        document.body.getAttribute('data-wf-scroll-motion') === 'none' ||
        reducedMotionMediaQuery.matches
      );
    }

    function setFocusable($el, action) {
      var initialTabindex;

      switch (action) {
        case 'add':
          initialTabindex = $el.attr('tabindex');

          if (initialTabindex) {
            $el.attr('data-wf-tabindex-swap', initialTabindex);
          } else {
            $el.attr('tabindex', '-1');
          }
          break;

        case 'remove':
          initialTabindex = $el.attr('data-wf-tabindex-swap');
          if (initialTabindex) {
            $el.attr('tabindex', initialTabindex);
            $el.removeAttr('data-wf-tabindex-swap');
          } else {
            $el.removeAttr('tabindex');
          }
          break;
      }

      $el.toggleClass('wf-force-outline-none', action === 'add');
    }

    /**
     * Determine if we should execute custom scroll
     */
    function validateScroll(evt) {
      var target = evt.currentTarget;
      if (
        // Bail if in Designer
        Webflow.env('design') ||
        // Ignore links being used by jQuery mobile
        (window.$.mobile && /(?:^|\s)ui-link(?:$|\s)/.test(target.className))
      ) {
        return;
      }

      var hash = linksToCurrentPage(target) ? target.hash : '';
      if (hash === '') return;

      var $el = $(hash);
      if (!$el.length) {
        return;
      }

      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      updateHistory(hash, evt);

      window.setTimeout(
        function () {
          scroll($el, function setFocus() {
            setFocusable($el, 'add');
            $el.get(0).focus({preventScroll: true});
            setFocusable($el, 'remove');
          });
        },
        evt ? 0 : 300
      );
    }

    function updateHistory(hash) {
      // Push new history state
      if (
        loc.hash !== hash &&
        history &&
        history.pushState &&
        // Navigation breaks Chrome when the protocol is `file:`.
        !(Webflow.env.chrome && loc.protocol === 'file:')
      ) {
        var oldHash = history.state && history.state.hash;
        if (oldHash !== hash) {
          history.pushState({hash}, '', hash);
        }
      }
    }

    function scroll($targetEl, cb) {
      var start = $win.scrollTop();
      var end = calculateScrollEndPosition($targetEl);

      if (start === end) return;

      var duration = calculateScrollDuration($targetEl, start, end);

      var clock = Date.now();
      var step = function () {
        var elapsed = Date.now() - clock;
        window.scroll(0, getY(start, end, elapsed, duration));

        if (elapsed <= duration) {
          animate(step);
        } else if (typeof cb === 'function') {
          cb();
        }
      };

      animate(step);
    }

    function calculateScrollEndPosition($targetEl) {
      // If a fixed header exists, offset for the height
      var $header = $(headerSelector);
      var offsetY =
        $header.css('position') === 'fixed' ? $header.outerHeight() : 0;
      var end = $targetEl.offset().top - offsetY;

      // If specified, scroll so that the element ends up in the middle of the viewport
      if ($targetEl.data('scroll') === 'mid') {
        var available = $win.height() - offsetY;
        var elHeight = $targetEl.outerHeight();
        if (elHeight < available) {
          end -= Math.round((available - elHeight) / 2);
        }
      }
      return end;
    }

    function calculateScrollDuration($targetEl, start, end) {
      if (reducedMotionEnabled()) return 0;

      var mult = 1;
      // Check for custom time multiplier on the body and the scroll target
      $body.add($targetEl).each(function (_, el) {
        var time = parseFloat(el.getAttribute('data-scroll-time'));
        if (!isNaN(time) && time >= 0) {
          mult = time;
        }
      });

      return (472.143 * Math.log(Math.abs(start - end) + 125) - 2000) * mult;
    }

    function getY(start, end, elapsed, duration) {
      if (elapsed > duration) {
        return end;
      }

      return start + (end - start) * ease(elapsed / duration);
    }

    function ease(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    }
    function ready() {
      var {WF_CLICK_EMPTY, WF_CLICK_SCROLL} = NS_EVENTS;

      $doc.on(WF_CLICK_SCROLL, localHrefSelector, validateScroll);

      /**
       * Prevent empty hash links from triggering scroll.
       * Legacy feature to preserve: use the default "#" link
       * to trigger an interaction, and do not want the page
       * to scroll to the top.
       */
      $doc.on(WF_CLICK_EMPTY, emptyHrefSelector, function (e) {
        e.preventDefault();
      });

      document.head.insertBefore(focusStylesEl, document.head.firstChild);
    }

    // Export module
    return {ready};
  })
);

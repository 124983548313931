/* eslint-env browser */

const queryContainerElement = (elementId) =>
  document.querySelector(`[data-w-id="${elementId}"]`);

const getFrontendModule = () => window.Webflow.require('spline');

const difference = (arr1, arr2) => arr1.filter((x) => !arr2.includes(x));

export const getPluginConfig = (actionItemConfig, key) => {
  return actionItemConfig.value[key];
};

export const getPluginDuration = () => {
  // Not used by this plugin
  return null;
};

const DEFAULT_VALUES = Object.freeze({
  positionX: 0,
  positionY: 0,
  positionZ: 0,
  rotationX: 0,
  rotationY: 0,
  rotationZ: 0,
  scaleX: 1,
  scaleY: 1,
  scaleZ: 1,
});

export const getPluginOrigin = (refState, actionItem) => {
  // Determine which props we care about in the destination
  const destination = actionItem.config.value;
  const destinationKeys = Object.keys(destination);
  // Check the current state for any missing origin props
  if (refState) {
    const stateKeys = Object.keys(refState);
    const diffKeys = difference(destinationKeys, stateKeys);
    // If new props are needed, merge new origin values with current state
    if (diffKeys.length) {
      const origin = diffKeys.reduce((result, key) => {
        result[key] = DEFAULT_VALUES[key];
        return result;
      }, refState);
      return origin;
    }
    // No difference, return current state
    return refState;
  }
  // State doesn't exist so define new origin based on destination
  const origin = destinationKeys.reduce((result, key) => {
    result[key] = DEFAULT_VALUES[key];
    return result;
  }, {});
  return origin;
};

export const getPluginDestination = (actionItemConfig) => {
  return actionItemConfig.value;
};

export const createPluginInstance = (element, actionItem) => {
  // In this case, we define pluginInstance as a reference to the container element
  const pluginElementId = actionItem?.config?.target?.pluginElement;
  return pluginElementId ? queryContainerElement(pluginElementId) : null;
};

export const renderPlugin = (containerElement, refState, actionItem) => {
  const frontendModule = getFrontendModule();
  if (!frontendModule) return;
  const instance = frontendModule.getInstance(containerElement);
  const objectId = actionItem.config.target.objectId;

  const renderSpline = (spline) => {
    if (!spline) {
      throw new Error('Invalid spline app passed to renderSpline');
    }
    const obj = objectId && spline.findObjectById(objectId);
    if (!obj) {
      return;
    }
    const {PLUGIN_SPLINE: props} = refState;
    if (props.positionX != null) {
      obj.position.x = props.positionX;
    }
    if (props.positionY != null) {
      obj.position.y = props.positionY;
    }
    if (props.positionZ != null) {
      obj.position.z = props.positionZ;
    }
    if (props.rotationX != null) {
      obj.rotation.x = props.rotationX;
    }
    if (props.rotationY != null) {
      obj.rotation.y = props.rotationY;
    }
    if (props.rotationZ != null) {
      obj.rotation.z = props.rotationZ;
    }
    if (props.scaleX != null) {
      obj.scale.x = props.scaleX;
    }
    if (props.scaleY != null) {
      obj.scale.y = props.scaleY;
    }
    if (props.scaleZ != null) {
      obj.scale.z = props.scaleZ;
    }
  };

  if (instance) {
    // Render spline if the app instance is already loaded
    renderSpline(instance.spline);
  } else {
    // Otherwise, store latest render as a callback to fire when loaded
    frontendModule.setLoadHandler(containerElement, renderSpline);
  }
};

export const clearPlugin = () => {
  // Not used by this plugin
  return null;
};

/**
 * Webflow: Interactions 2
 */
var Webflow = require('./webflow-lib');
var ix2 = require('@packages/systems/ix2/engine');

ix2.setEnv(Webflow.env);

Webflow.define(
  'ix2',
  (module.exports = function () {
    return ix2;
  })
);

/* globals window */

/**
 * Webflow: IX Event triggers for other modules
 */

'use strict';

var $ = window.jQuery;
var api = {};
var eventQueue = [];
var namespace = '.w-ix';

var eventTriggers = {
  reset: function (i, el) {
    el.__wf_intro = null;
  },
  intro: function (i, el) {
    if (el.__wf_intro) {
      return;
    }
    el.__wf_intro = true;
    $(el).triggerHandler(api.types.INTRO);
  },
  outro: function (i, el) {
    if (!el.__wf_intro) {
      return;
    }
    el.__wf_intro = null;
    $(el).triggerHandler(api.types.OUTRO);
  },
};

api.triggers = {};

api.types = {
  INTRO: 'w-ix-intro' + namespace,
  OUTRO: 'w-ix-outro' + namespace,
};

// Trigger any events in queue + restore trigger methods
api.init = function () {
  var count = eventQueue.length;
  for (var i = 0; i < count; i++) {
    var memo = eventQueue[i];
    memo[0](0, memo[1]);
  }
  eventQueue = [];
  $.extend(api.triggers, eventTriggers);
};

// Replace all triggers with async wrapper to queue events until init
api.async = function () {
  for (var key in eventTriggers) {
    var func = eventTriggers[key];
    if (!eventTriggers.hasOwnProperty(key)) {
      continue;
    }

    // Replace trigger method with async wrapper
    api.triggers[key] = function (i, el) {
      eventQueue.push([func, el]);
    };
  }
};

// Default triggers to async queue
api.async();

module.exports = api;

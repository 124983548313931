/* globals document, MouseEvent */

/**
 * Webflow: focus
 */

var Webflow = require('./webflow-lib');

/*
 * Safari has a weird bug where it doesn't support :focus for links with hrefs,
 * buttons, and input[type=button|submit], so we listen for mousedown events
 * instead and force the element to emit a focus event in those cases.

 * See these webkit bugs for reference:
 * https://bugs.webkit.org/show_bug.cgi?id=22261
 * https://bugs.webkit.org/show_bug.cgi?id=229895
 */
Webflow.define(
  'focus',
  (module.exports = function () {
    var capturedEvents = [];
    var capturing = false;

    function captureEvent(e) {
      if (capturing) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        capturedEvents.unshift(e);
      }
    }

    /*
     * The only mousedown events we care about here are ones emanating from
     * (A) anchor links with href attribute,
     * (B) non-disabled buttons,
     * (C) non-disabled textarea,
     * (D) non-disabled inputs of type "button", "reset", "checkbox", "radio", "submit"
     * (E) non-interactive elements (button, a, input, textarea, select) that have a tabindex with a numeric value
     * (F) audio elements
     * (G) video elements with controls attribute
     */
    function isPolyfilledFocusEvent(e) {
      var el = e.target;
      var tag = el.tagName;
      return (
        (/^a$/i.test(tag) && el.href != null) || // (A)
        (/^(button|textarea)$/i.test(tag) && el.disabled !== true) || // (B) (C)
        (/^input$/i.test(tag) &&
          /^(button|reset|submit|radio|checkbox)$/i.test(el.type) &&
          !el.disabled) || // (D)
        (!/^(button|input|textarea|select|a)$/i.test(tag) &&
          !Number.isNaN(Number.parseFloat(el.tabIndex))) || // (E)
        /^audio$/i.test(tag) || // (F)
        (/^video$/i.test(tag) && el.controls === true) // (G)
      );
    }

    function handler(e) {
      if (isPolyfilledFocusEvent(e)) {
        // start capturing possible out-of-order mouse events
        capturing = true;

        /*
         * enqueue the focus event _after_ the current batch of events, which
         * includes any blur events. The correct order of events is:
         *
         * [this element] MOUSEDOWN               <-- this event
         * [previously active element] BLUR
         * [previously active element] FOCUSOUT
         * [this element] FOCUS                   <-- forced event
         * [this element] FOCUSIN                 <-- forced event
         * [this element] MOUSEUP                 <-- possibly captured event (it may have fired _before_ the FOCUS event)
         * [this element] CLICK                   <-- possibly captured event (it may have fired _before_ the FOCUS event)
         */
        setTimeout(() => {
          // stop capturing possible out-of-order mouse events
          capturing = false;

          // trigger focus event
          e.target.focus();

          // re-dispatch captured mouse events in order
          while (capturedEvents.length > 0) {
            var event = capturedEvents.pop();
            event.target.dispatchEvent(new MouseEvent(event.type, event));
          }
        }, 0);
      }
    }

    function ready() {
      if (
        typeof document !== 'undefined' &&
        document.body.hasAttribute('data-wf-focus-within') &&
        Webflow.env.safari
      ) {
        document.addEventListener('mousedown', handler, true);
        document.addEventListener('mouseup', captureEvent, true);
        document.addEventListener('click', captureEvent, true);
      }
    }

    // Export module
    return {ready};
  })
);
